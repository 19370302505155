
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const eppcms = ref([] as any)
    
    const goToStoreExportGroup = (id) => {
      router.push({ name: "store_export_group", params: {id: id} })
    }

    const query = () => {
      store
        .dispatch(Actions.GET_EPPCMS, {status: 'approve'})
        .then(() => {
          eppcms.value = store.getters.currentEppcms
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    query()

    return {
      goToStoreExportGroup,
      eppcms
    }
  }
})
